body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  font-size: 16px;
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
  margin: 24px;
}
h2 {
  font-size: 2rem;
  margin: 16px;
}

h3 {
  font-size: 1.5rem;
  margin: 12px;
}

button {
  &:hover {
    cursor: pointer;
    transition: ease 0.2s;
    border: 1px blue solid;
  }
}
