@use "../app/variables";

.char-create-form {
  display: flex;
  flex-direction: column;
}

.core-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 4px;
}

.additional-information {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 5px;
  margin: 8px;
  box-shadow: variables.$subtle-box-shadow;
}

.column-info-display {
  margin: 16px 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding-bottom: 12px;
}

.column-info-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 100%;

  h3 {
    width: 100%;
    text-align: center;
    margin: 16px;
  }
}

.prof-exp-label {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 30px;
  margin-right: 5px;

  p {
    transform: rotate(-50deg);
    font-size: 0.8rem;
  }
}

.prof-exp-label-save {
  position: absolute;
  align-self: flex-end;
  margin-top: 30px;
  margin-right: 30px;
  transform: rotate(-50deg);
  font-size: 0.8rem;
}

.row-info-display {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.input-combo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
}

.core-input {
  border: 0.05rem solid black;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  max-width: 250px;
  padding-left: 8px;
  font-size: 1rem;
}

.input-sized-p {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-editor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 4px;
  width: 100%;
  margin: 2px 0;
  padding: 0 8px;
}

.skill-editor-name {
  margin: 0;
  font-weight: normal;
}

.skill-editor-stuff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 77px;
}

.skill-editor-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40px;
}

.skill-editor-val {
  margin: 0;
}

.skill-btn {
  background-color: rgb(233, 233, 233);
  border-radius: 50%;
  width: 20px;
  margin: 0.5px;
  &:hover {
    border: none;
    background-color: rgb(204, 204, 248);
  }
}

.clicked {
  background: linear-gradient(to left top, rgb(162, 162, 250), rgb(90, 90, 199));
}

.disabled {
  background-color: rgb(202, 200, 200);
  &:hover {
    border: none;
    background-color: rgb(202, 200, 200);
    cursor: default;
  }
}

.save-editor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 80%;
  margin: 2px 0;
}

.save-editor-name {
  font-weight: normal;
  margin: 0;
}
.save-editor-val {
  margin: 0;
  margin-right: 29px;
}

.save-editor-stuff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-complete-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  button {
    width: 50%;
    border: none;
    background-color: white;
    height: 40px;
  }
}

#save {
  background-color: rgb(195, 235, 195);
}

#cancel {
  background-color: rgb(253, 176, 176);
}

// .action {
//     display: flex;
//     flex-direction: row;
//     align-items: baseline;
//     align-content: flex-start;
//     border: solid black 1px;
// }

// .action-info {
//     display: flex;
//     flex-direction: column;
// }
