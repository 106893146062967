.roll-space {
  max-width: 560px;
  margin: 15px;
}

.dice-tray-container {
  display: flex;
  justify-content: space-around;
  padding: 2px;
}

.dice-tray {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px blue solid;
  border-radius: 5px;
  min-height: 75px;
  width: 100%;
}

.dice-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: center;
  padding: 5px;
  height: 80px;
}

.buttons-row {
  display: flex;
  justify-content: space-evenly;
}

.dice-history {
  border: 1px blue solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  min-height: 115px;
  max-height: 270px;
  height: fit-content;
  padding: 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dice-result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  height: fit-content;
  max-width: 550px;
  flex-wrap: wrap;
}

.dice-result-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dice-result-total {
  font-size: 2rem;
  color: #479ad1;
  font-weight: bold;
  margin-left: 16px;
}

.dice-result-combo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: start;
  padding: 2px;
  transition: min-width .5s;

  .plus {
    margin-left: 2px;
    cursor: default;
  }

  .dice-wrapper {
    :hover {
      cursor: default;
    }
  }

}
.dice-result-combo:hover {
  .dice-result-vantage {
    min-width: 120px;
  }
  .adv-hide {
    top: 0px;
    left: 60px;
  }
}

.modifier-print {
  font-size: 1.4rem;
  margin: 2px;
  margin-right: 4px;
}

.clicked-advantage {
  background-color: #c3ebc3;
}

.clicked-disadvantage {
  background-color: #fdb0b0;
}

.roll-button {
  width: 160px;
  height: 50px;
  margin: 24px 8px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.dice-wrapper {
  position: relative;
  background-color: transparent;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  padding: 2px;

  :hover {
    cursor: pointer;
  }
}

.mod-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mod-input {
  border: 0.05rem solid black;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 55px;
  font-size: 1rem;
  text-align: center;
}

.dice-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  z-index: 2;
}

.dice-result-vantage {
  display: flex;
  flex-direction: row;
  min-width: 70px;
  position: relative;

  .adv-top {
    z-index: 1;
  }
  .adv-hide {
    z-index: 0;
    position: absolute;
    top: 7px;
    left: 7px;
    transition: top 0.3s, left 0.3s;
  }
}

.unused-roll {
  fill: #808080;
}

.used-roll {
  fill: #5669f5;
}
