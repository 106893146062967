@use "../../app/variables";

.ability-editor {
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: center;
  margin: 8px 8px;
  border-radius: 5px;
  box-shadow: variables.$subtle-box-shadow;
}

.ability-name {
  justify-self: flex-start;
  font-weight: bold;
  margin: 8px 8px 4px;
}

.ability-input {
  width: 50%;
  margin-top: 8px;
  box-sizing: border-box;
  justify-self: center;
  text-align: center;
  font-size: 2rem;
  border: variables.$round-thin-border;
  margin: 4px 8px 4px;
  border-radius: 5px;
}

.ability-mod {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 40px;
  font-size: 1rem;
  margin: 4px 8px 8px;
  padding: 2px 0;
}
