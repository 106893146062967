@use "../app/variables";
/* Removing scroller for numeric inputs */
// input[type=number] {
//     -moz-appearance:textfield;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
//     padding: 0;
// }
.App {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
  background: #ebebeb;
}

.main-page {
  width: 80%;
  max-width: 700px;
  min-height: 700px;
  box-shadow: variables.$subtle-box-shadow;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding-bottom: 24px;
}
